import { useHints, useRequestInfo } from "~/utils/theme";

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */

export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  return requestInfo.userPrefs.theme ?? hints.theme;
}
