"use client";

import { Link } from "@remix-run/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

enum CookieBannerState {
  "unknown",
  "accepted",
  "not-yet-accepted",
}

export const hasAcceptedCookieBanner = () => {
  return localStorage.getItem("cookieBanner") === "accepted";
};

export const CookieBanner = () => {
  const [state, setState] = useState<CookieBannerState>(
    CookieBannerState.unknown,
  );
  useEffect(() => {
    if (localStorage.getItem("cookieBanner") === "accepted") {
      setState(CookieBannerState.accepted);
    } else {
      setState(CookieBannerState["not-yet-accepted"]);
    }
  }, []);

  const accept = () => {
    localStorage.setItem("cookieBanner", "accepted");
    setState(CookieBannerState.accepted);
    ReactGA.initialize("G-GL002FJXSQ");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  };

  return (
    <AnimatePresence>
      {state === CookieBannerState["not-yet-accepted"] && (
        <>
          <div className="h-28" />

          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            className="fixed inset-0 z-[1001] flex items-center justify-center"
          >
            <div className="flex items-start max-w-xl gap-4 px-12 py-12 text-white bg-indigo-600 border-t-4 border-indigo-200 rounded-xl">
              <div>
                <p className="text-balance">
                  Um dir ein optimales Nutzererlebnis zu bieten, möchten wir
                  Cookies verwenden.{" "}
                  <p>
                    Bitte bestätige dein Einverständnis durch Klicken auf
                    Zustimmen.
                  </p>
                  Weitere Informationen über unsere Cookie-Richtlinien findest
                  Du in unserer{" "}
                  <Link to="/datenschutz">Datenschutzerklärung.</Link>
                </p>
                <div className="flex justify-end">
                  <button
                    className="px-4 py-2 ml-4 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-indigo-500 border border-transparent rounded-lg focus:shadow-outline-indigo hover:bg-indigo-600 focus:outline-none active:bg-indigo-600"
                    onClick={accept}
                  >
                    Zustimmen
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
          <div className="sticky inset-0 z-[1000] h-screen bg-gray-900/80"></div>
        </>
      )}
    </AnimatePresence>
  );
};
