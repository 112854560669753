import "@onzeit/shared/src/globals.css";
// import { captureRemixErrorBoundaryError } from "@sentry/remix";

import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
} from "@remix-run/react";
import { CookieBanner } from "./components/CookieBanner";

import { DataFunctionArgs, json } from "@remix-run/cloudflare";
import "./global.css";
import { ClientHintCheck, getHints, useNonce } from "./utils/client-hints";
import { getTheme } from "./utils/theme-session.server";
import { useTheme } from "./utils/useTheme";

// export const ErrorBoundary = () => {
//   const error = useRouteError();
//   captureRemixErrorBoundaryError(error);
//   return <div>Something went wrong</div>;
// };

export async function loader({ request }: DataFunctionArgs) {
  return json({
    requestInfo: {
      hints: getHints(request),
      userPrefs: {
        theme: getTheme(request),
      },
    },
  });
}

export default function App() {
  const nonce = useNonce();
  const theme = useTheme();
  const location = useLocation();

  return (
    <html lang="de" className={location.pathname.startsWith("/app") ? theme : "light"}>
      {/* clsx(theme) */}
      <head>
        <ClientHintCheck nonce={nonce} />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"
        />

        <meta name="application-name" content="Onzeit" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Onzeit" />
        <meta name="description" content="" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#312e81" />
        <meta name="msapplication-tap-highlight" content="no" />
        {/* <meta
            name="theme-color"
            id="theme-color"
            content={this.props.theme === "dark" ? "#1e293b" : "#ffffff"}
          /> */}

        <meta name="apple-mobile-web-app-capable" content="yes" />

        <link rel="icon" type="image/png" sizes="196x196" href="/icons/favicon-196.png" />

        <link rel="apple-touch-icon" href="/icons/apple-icon-180.png" />

        <meta name="apple-mobile-web-app-capable" content="yes"></meta>

        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-2048-2732.png"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1668-2388.png"
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1536-2048.png"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1668-2224.png"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1620-2160.png"
          media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1290-2796.png"
          media="(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1179-2556.png"
          media="(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1284-2778.png"
          media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1170-2532.png"
          media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1125-2436.png"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1242-2688.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-828-1792.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-1242-2208.png"
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-750-1334.png"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/icons/apple-splash-640-1136.png"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />

        <link rel="shortcut icon" href="/icons/favicon-196.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://www.onzeit.com" />
        <meta name="twitter:title" content="Onzeit" />
        <meta
          name="twitter:description"
          content="Onzeit - Das smarte Buchungstool für mobile DienstleisterInnen"
        />
        <meta name="twitter:image" content="https://www.onzeit.com/icons/favicon-196.png" />
        {/* <meta name="twitter:creator" content="@DavidWShadow" /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Onzeit" />
        <meta
          property="og:description"
          content="Onzeit - Das smarte Buchungstool für mobile DienstleisterInnen"
        />
        <meta property="og:site_name" content="Onzeit" />
        <meta property="og:url" content="https://www.onzeit.com" />
        <meta property="og:image" content="https://www.onzeit.com/icons/favicon-196.png" />

        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <CookieBanner />
        {/* <UserTracking /> */}
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}
